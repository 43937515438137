import React from 'react'
import SmoothCollapse from 'react-smooth-collapse'

export default ({
    open, 
    children,
    duration = 250
}) => {
    return (
        <div className={`fade-collapse ${ open ? 'visible' : ''}`}>
            <SmoothCollapse 
                expanded={open}
                heightTransition={`${ duration/1000 }s`}
            >
                { children }
            </SmoothCollapse>
        </div>
    )
}