import React, { useState, useEffect } from 'react'
import Link from 'components/globals/link'

import { shuffle } from 'lodash'

export default ({
    project,
    index
}) => {
    if (!project) {
        return null
    }

    const {
        projectFields
    } = project

    const {
        gridVideo,
        tileBadge
    } = projectFields || {}

    const [servicesToShow, setServicesToShow] = useState([])

    const _image = (() => {
        let response = null

        if (project.featuredImage && project.featuredImage.node) {
            const { mediaItemUrl, mediaDetails } = project.featuredImage.node

            if (mediaItemUrl.endsWith('gif')) {
                response = mediaItemUrl
            } else {
                if (mediaDetails) {
                    mediaDetails.sizes.forEach(size => {
                        if (size.name === 'large') {
                            response = size.sourceUrl
                        }
                    })
                }

                if (!response) {
                    response = mediaItemUrl
                }
            }
        }

        return response
    })()

    if (!_image) {
        return null
    }

    useEffect(() => {
        const shuffledServices = shuffle([...project.servicesRendered.nodes])

        if (shuffledServices.length > 2) {
            shuffledServices.length = 2
        }

        setServicesToShow(shuffledServices)
    }, [])

    return (
        <div
            style={{
                transitionDelay: (index / 6 + 0.5) + 's'
            }}
            data-scroll
            data-scroll-offset={'10%, 10%'}
        >
            <Link
                to={project.link}
                className={'has-cursor-effect inverted-cursor'}
            >
                { tileBadge &&
                    <div className='tile-badge'>
                        <img src={tileBadge.mediaItemUrl} />
                    </div>
                }
                <div
                    className={'image'}
                >
                    {gridVideo ?
                        <video className='standard-image' muted loop autoPlay playsInline>
                            <source src={gridVideo.mediaItemUrl} type="video/mp4" />
                        </video>
                        :
                        <div className={'standard-image'} style={{
                            backgroundImage: `url(${_image})`
                        }} />
                    }
                </div>
                <span>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: project.title }} className="project-title" />
                        <i className="fe fe-arrow-right project-arrow" />
                    </div>
                    <div>
                        {servicesToShow.map((service, _index) => {
                            let response = _index ? ' & ' : ''

                            response += service.name

                            return response
                        })}
                    </div>
                </span>
            </Link>
        </div>
    )
}