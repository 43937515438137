import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { MainContext } from 'context/context'
import Button from 'components/globals/button'
import ProjectsGridFilter from 'components/flexibleContent/projectsGridFilter'
import ProjectsGridItem from 'components/globals/projectsGridItem'
import { isMobile } from "utils"

import { useStaticQuery, graphql } from "gatsby"

import { chunk } from 'lodash'

export default ({
    projectsSelected,
    projects,
    includeAllProjects,
    category,
    thirds,
    currentProjectId,
    isScrolledIntoView
}) => {
    const [visibleProjects, setVisibleProjects] = useState(thirds ? 3 : 10)
    const visibleProjectsRef = useRef(visibleProjects)
    visibleProjectsRef.current = visibleProjects

    const [filterValues, setFilterValues] = useState({})
    const [isVisible, setIsVisible] = useState(true)

    const [expandWrapperHeight, setExpandWrapperHeight] = useState(0)

    const {
        projects: {
            nodes: _projects
        },
        industries: {
            nodes: industries
        },
        servicesRendered: {
            nodes: servicesRendered
        }
    } = useStaticQuery(graphql`
		query {
			siteData {
				projects (first: 999) {
                    nodes {
                        databaseId
                        title
                        link
                        industries {
                            nodes {
                                name
                                databaseId
                            }
                        }
                        servicesRendered {
                            nodes {
                                name
                                databaseId
                            }
                        }
                        featuredImage {
                            node {
                                mediaItemUrl
                            }
                        }
                        projectFields {
                            labsProject
                            gridVideo {
                                mediaItemUrl
                            }
                            tileBadge {
                                mediaItemUrl
                            }
                        }
                    }
                }
                industries {
                    nodes {
                        id
                        databaseId
                        name
                    }
                }
                servicesRendered {
                    nodes {
                        id
                        databaseId
                        name
                    }
                }
			}
		}
    `).siteData

    const filterValueKeys = Object.keys(filterValues)

    const passedInProjects = projects ? projects.map(node => node.project) : null

    const __projects = (() => {
        if(filterValueKeys.length || !passedInProjects){
            return _projects
        }

        return passedInProjects
    })()

    const projectsList = __projects.filter(project => {
        const { 
            projectFields,
            databaseId
        } = project

        let shouldReturn = currentProjectId ? databaseId !== currentProjectId : true

        if (!shouldReturn || (projectFields && projectFields.labsProject)) {
            return false
        }

        if (category) {
            const serviceIds = category.servicesFields.subServices.map(_service => _service.databaseId)
            const projectServiceIds = project.servicesRendered.nodes.map(node => node.databaseId)

            shouldReturn = false

            serviceIds.some(serviceId => {
                if (~projectServiceIds.indexOf(serviceId)) {
                    shouldReturn = true
                    return true
                }
            })
        }

        if (!filterValueKeys.length) {
            return true
        }

        filterValueKeys.forEach(key => {
            const value = filterValues[key]

            const hasValue = project[key].nodes.find(projectValue => projectValue.databaseId === value.value)

            if (!hasValue) {
                shouldReturn = false
            }
        })

        return shouldReturn
    }).map(project => {
        return {
            project
        }
    })

    const visibleProjectsList = projectsList.length > visibleProjects ? projectsList.slice(0, visibleProjects) : projectsList

    const chunkedProjects = chunk(visibleProjectsList, 5)

    const {
        addInvertedHeaderSection,
        addDeepPaddedHeaderSection,
        recalcLocomotive
    } = useContext(MainContext)

    const elementRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current && !isMobile) {
            addInvertedHeaderSection(elementRef.current)
            addDeepPaddedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    useEffect(() => {
        setTimeout(() => {
            const containerHeight = elementRef.current.getBoundingClientRect().height

            setExpandWrapperHeight(containerHeight)

            setTimeout(() => {
                recalcLocomotive()
            }, 1000)
        }, 10)
    }, [visibleProjects])

    const showMore = () => {
        setVisibleProjects(visibleProjectsRef.current + (isMobile ? 5 : 10))
    }

    const handleChange = (key, value) => {
        setIsVisible(false)

        setTimeout(() => {
            const _filterValues = { ...filterValues }

            _filterValues[key] = value

            setFilterValues(_filterValues)

            setIsVisible(true)

            setTimeout(() => {
                if (window.cursorHandler) {
                    window.cursorHandler.attachListeners()
                }

                recalcLocomotive()
            }, 10)
        }, 400)
    }

    const clearFilters = () => {
        setIsVisible(false)

        setTimeout(() => {
            setIsVisible(true)
            setFilterValues({})

            if (window.cursorHandler) {
                setTimeout(() => {
                    window.cursorHandler.attachListeners()
                }, 10)
            }
        }, 400)
    }

    return useMemo(() => {
        return (
            <>
                {
                    category ?
                        null
                        :
                        thirds ?
                            <h3>
                                Something else, perhaps?
                    </h3>
                            :
                            <ProjectsGridFilter
                                filters={[
                                    {
                                        key: 'industry',
                                        pluralKey: 'industries',
                                        values: industries
                                    }, {
                                        key: 'service',
                                        pluralKey: 'servicesRendered',
                                        values: servicesRendered
                                    }
                                ]}
                                handleChange={handleChange}
                                clearFilters={clearFilters}
                                filterValues={filterValues}
                            />
                }
                <div
                    className={`expand-wrapper ${isVisible ? 'visible' : ''} ${category ? 'no-top-shelf' : ''}`}
                    style={{
                        height: (expandWrapperHeight ? expandWrapperHeight + 'px' : undefined)
                    }}
                >
                    <ul
                        className={'container'}
                        ref={ref => elementRef.current = ref}
                    >
                        {
                            projectsSelected ?
                                <li className={`group ${thirds ? 'thirds' : ''}`}>
                                    {projectsSelected.map((project, projectIndex) => {
                                        return (
                                            <ProjectsGridItem
                                                project={project.project}
                                                key={projectIndex}
                                            />
                                        )
                                    })}
                                </li>
                            :
                                chunkedProjects.map((_chunk, chunkIndex) => {
                                    return (
                                        <li key={JSON.stringify(filterValues) + chunkIndex} className={`group ${thirds ? 'thirds' : ''}`}>
                                            {_chunk.map((row, index) => {
                                                const { project } = row
                                                return (
                                                    <ProjectsGridItem
                                                        project={project}
                                                        key={project.databaseId}
                                                        index={index}
                                                    />
                                                )
                                            })}
                                        </li>
                                    )
                                })
                        }
                    </ul>
                </div>
                <div className={'button-row'}>
                    <Button
                        inverted
                        label={(includeAllProjects || category) ? 'Show more projects' : 'See all projects'}
                        url={includeAllProjects ? undefined : '/our-work'}
                        onClick={includeAllProjects ? showMore : undefined}
                        icon={'arrow-right'}
                        iconRight
                    />
                </div>
            </>
        )
    }, [isScrolledIntoView, visibleProjects, filterValues, isVisible, expandWrapperHeight])

}