import React from 'react'
import Select from 'react-select'

export default ({
    filters,
    handleChange,
    filterValues,
    clearFilters
}) => {
    const hasValues = Object.keys(filterValues).length

    return (
        <div className={`projects-grid-filter container ${hasValues ? 'has-values' : ''}`}>
            <label>
                View projects from:
            </label>
            { filters.map(({
                values,
                key,
                pluralKey
            }, index) => {
                return (
                    <span className={'has-cursor-effect'} key={index}>
                        <Select
                            key={key}
                            className={'select'}
                            options={values.map(_value => {
                                return {
                                    value: _value.databaseId,
                                    label: _value.name
                                }
                            })}
                            onChange={value => handleChange(pluralKey, value)}
                            value={filterValues[pluralKey] || null}
                            placeholder={`any ${key}`}
                            classNamePrefix={'select-box'}
                        />
                    </span>
                )
            })}
            <button 
                className={'has-cursor-effect clear-filters'}
                onClick={clearFilters}
            >
                <i className={'fe fe-x'} />
            </button>
        </div>
    )
}